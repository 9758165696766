<template>
  <div class="okOtherTry">
    <h3 v-if="postData.S_OP_Json.S_OP_CG_brand == '梦戴维'">梦戴维</h3>
    <h3 v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'">CRT</h3>


    <div>
      <el-button type="info" @click="tb">同步数据</el-button>
      <el-button type="info" @click="showyb=true">确认眼别数据</el-button>
      <el-button type="info" v-if="!istjsd" @click="tjsd">提交试戴</el-button>
      <el-button type="info" v-if="istjsd">已提交</el-button>
    </div>
    <p>屈光度</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>FK</li>
          <li>SK</li>
          <li>视力</li>
          <li>HVID</li>
          <li>E值</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_CG_R_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_XOD')" v-model="postData.S_OP_Json.S_OP_CG_R_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_FKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_FKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_SKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_SKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_VAOD')" v-model="postData.S_OP_Json.S_OP_CG_R_VAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_HVIDOD')" v-model="postData.S_OP_Json.S_OP_CG_R_HVIDOD" placeholder="请输入内容"></el-input></li>

          <li><el-input @change="zh('S_OP_CG_R_EOD')" v-model="postData.S_OP_Json.S_OP_CG_R_EOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_CG_R_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_XOS')" v-model="postData.S_OP_Json.S_OP_CG_R_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_FKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_FKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_SKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_SKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_VAOS')" v-model="postData.S_OP_Json.S_OP_CG_R_VAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_HVIDOS')" v-model="postData.S_OP_Json.S_OP_CG_R_HVIDOS" placeholder="请输入内容"></el-input></li>

          <li><el-input @change="zh('S_OP_CG_R_EOS')" v-model="postData.S_OP_Json.S_OP_CG_R_EOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <p>试戴片数据</p>
    <div class="srA">
      <div>
        <ul v-if="postData.S_OP_Json.S_OP_CG_brand != 'CRT'" class="content-sr padding-20">
          <li></li>
          <li>镜片类型</li>
          <li>定位弧</li>
          <li>降幅</li>
          <li>直径</li>
          <li>颜色</li>
          <li>基弧</li>
          <li>环曲面</li>
        </ul>
        <ul v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'" class="content-sr padding-20">
          <li></li>
          <li>编号</li>
          <li>BC</li>
          <li>RZD1</li>
          <li>RZD1</li>
          <li>LZA1</li>
          <li>LZA2</li>
          <li>power</li>
          <li>DIA</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li v-if="postData.S_OP_Json.S_OP_CG_brand !== 'CRT'">
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOD" @change="bh($event, 'od')" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li  v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'"><el-input @change="zh('S_OP_CG_Try_TypeOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOD" placeholder="请输入内容"></el-input></li>

          <li><el-input @change="zh('S_OP_CG_Try_DingWeiHuOD', 0, 'od')" v-model="postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_JiangFuOD', 0, 'od')" v-model="postData.S_OP_Json.S_OP_CG_Try_JiangFuOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_DIAOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_DIAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_ColorOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_ColorOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_JiHuOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_JiHuOD" placeholder="请输入内容"></el-input></li>
          <li>
            <el-input v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'" @change="zh('S_OP_CG_Try_HuanQuMianOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_HuanQuMianOD" placeholder="请输入内容"></el-input>
            <el-select v-if="postData.S_OP_Json.S_OP_CG_brand !== 'CRT'" v-model="postData.S_OP_Json.S_OP_CG_Try_HuanQuMianOD"  filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in hqm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'"><el-input @change="zh('S_OP_CG_Try_EValueOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_EValueOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li v-if="postData.S_OP_Json.S_OP_CG_brand !== 'CRT'">
            <el-select  v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOS" @change="bh($event, 'os')" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li  v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'"><el-input @change="zh('S_OP_CG_Try_TypeOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOS" placeholder="请输入内容"></el-input></li>

          <li><el-input @change="zh('S_OP_CG_Try_DingWeiHuOS', 0, 'os')" v-model="postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_JiangFuOS', 0, 'os')" v-model="postData.S_OP_Json.S_OP_CG_Try_JiangFuOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_DIAOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_DIAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_ColorOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_ColorOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_JiHuOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_JiHuOS" placeholder="请输入内容"></el-input></li>
          <li>
            <el-input v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'" @change="zh('S_OP_CG_Try_HuanQuMianOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_HuanQuMianOS" placeholder="请输入内容"></el-input>
            <el-select v-if="postData.S_OP_Json.S_OP_CG_brand !== 'CRT'" v-model="postData.S_OP_Json.S_OP_CG_Try_HuanQuMianOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in hqm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li v-if="postData.S_OP_Json.S_OP_CG_brand == 'CRT'"><el-input @change="zh('S_OP_CG_Try_EValueOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_EValueOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <div class="srA" v-if="dvod || dvos">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>E值</li>
          <li>调节因子</li>
          <li>小光学区SOZ</li>
          <li>超薄设计THIN</li>
        </ul>
        <ul v-if="dvod" class="content-sr">
          <li>OD</li>
          <li><el-input v-model="postData.S_OP_Json.S_OP_CG_Try_EValueOD" placeholder="请输入内容"></el-input></li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_adjustFactorOD"  @change="dvsz('od')" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in tjyz"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_SOZOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_THINOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul  v-if="dvos" class="content-sr padding-20">
          <li>OS</li>
          <li><el-input v-model="postData.S_OP_Json.S_OP_CG_Try_EValueOS" placeholder="请输入内容"></el-input></li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_adjustFactorOS"  @change="dvsz('os')" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in tjyz"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_SOZOS"  filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_THINOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>

    <p>片上验光</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>矫正视力</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_XOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_VAOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_VAOD" placeholder="请输入内容"></el-input></li>

        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_XOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_VAOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>试戴评估</p>
    <div class="srA">
      <div class="bts">动态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中心定位</li>
          <li>移动度</li>
          <li>染色评估</li>
          <li>10分钟后</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LocationOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ActivityOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LYJHOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lyjh"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_BesideCenterOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sfz"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LocationOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ActivityOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LYJHOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lyjh"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_BesideCenterOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in sfz"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
    <div class="tp">
      <UPfile :type="'VST_J'"></UPfile>
      <!--      <el-upload-->
      <!--          action="#"-->
      <!--          list-type="picture-card"-->
      <!--          :auto-upload="false">-->
      <!--        <i slot="default" class="el-icon-plus"></i>-->
      <!--        <div slot="file" slot-scope="{file}">-->
      <!--          <img-->
      <!--              class="el-upload-list__item-thumbnail"-->
      <!--              :src="file.url" alt=""-->
      <!--          >-->
      <!--          <span class="el-upload-list__item-actions">-->
      <!--        <span-->
      <!--            class="el-upload-list__item-preview"-->
      <!--            @click="handlePictureCardPreview(file)"-->
      <!--        >-->
      <!--          <i class="el-icon-zoom-in"></i>-->
      <!--        </span>-->
      <!--        <span-->
      <!--            v-if="!disabled"-->
      <!--            class="el-upload-list__item-delete"-->
      <!--            @click="handleDownload(file)"-->
      <!--        >-->
      <!--          <i class="el-icon-download"></i>-->
      <!--        </span>-->
      <!--        <span-->
      <!--            v-if="!disabled"-->
      <!--            class="el-upload-list__item-delete"-->
      <!--            @click="handleRemove(file)"-->
      <!--        >-->
      <!--          <i class="el-icon-delete"></i>-->
      <!--        </span>-->
      <!--      </span>-->
      <!--        </div>-->
      <!--      </el-upload>-->
      <!--      <el-dialog :visible.sync="dialogVisible">-->
      <!--        <img width="100%" :src="dialogImageUrl" alt="">-->
      <!--      </el-dialog>-->
    </div>
    <div class="srA">
      <div class="bts">静态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中央光学区</li>
          <li>反转弧区</li>
          <li>定位弧区</li>
          <li>周边弧区</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CenterOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zygxq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ReversalOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in fzq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LandOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zlq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_EdgeOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in byq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CenterOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zygxq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ReversalOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in fzq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LandOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zlq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_EdgeOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in byq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
    <div class="srA">
      <div class="bts" style="opacity: 0;">静态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>镜片直径</li>
          <li>佩戴情况</li>
          <li>改进</li>
          <li>睡眠测试</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_FitOD" placeholder="请输入内容"></el-input></li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_TrySituationOD" placeholder="请输入内容"></el-input></li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_ImproveOD" placeholder="请输入内容"></el-input></li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_SleepTestOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_FitOS" placeholder="请输入内容"></el-input></li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_TrySituationOS" placeholder="请输入内容"></el-input></li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_ImproveOS" placeholder="请输入内容"></el-input></li>
          <li><el-input  v-model="postData.S_OP_Json.S_OP_CG_A_SleepTestOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
    <div class="tp">
      <UPfile :type="'VST_D'"></UPfile>
      <!--      <el-upload-->
      <!--          action="#"-->
      <!--          list-type="picture-card"-->
      <!--          :auto-upload="false">-->
      <!--        <i slot="default" class="el-icon-plus"></i>-->
      <!--        <div slot="file" slot-scope="{file}">-->
      <!--          <img-->
      <!--              class="el-upload-list__item-thumbnail"-->
      <!--              :src="file.url" alt=""-->
      <!--          >-->
      <!--          <span class="el-upload-list__item-actions">-->
      <!--        <span-->
      <!--            class="el-upload-list__item-preview"-->
      <!--            @click="handlePictureCardPreview(file)"-->
      <!--        >-->
      <!--          <i class="el-icon-zoom-in"></i>-->
      <!--        </span>-->
      <!--        <span-->
      <!--            v-if="!disabled"-->
      <!--            class="el-upload-list__item-delete"-->
      <!--            @click="handleDownload(file)"-->
      <!--        >-->
      <!--          <i class="el-icon-download"></i>-->
      <!--        </span>-->
      <!--        <span-->
      <!--            v-if="!disabled"-->
      <!--            class="el-upload-list__item-delete"-->
      <!--            @click="handleRemove(file)"-->
      <!--        >-->
      <!--          <i class="el-icon-delete"></i>-->
      <!--        </span>-->
      <!--      </span>-->
      <!--        </div>-->
      <!--      </el-upload>-->
      <!--      <el-dialog :visible.sync="dialogVisible">-->
      <!--        <img width="100%" :src="dialogImageUrl" alt="">-->
      <!--      </el-dialog>-->
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.S_OP_CG_Remark">
      </el-input>
    </div>

    <el-dialog
        title="请选择眼别"
        :visible.sync="showyb"
        width="300px">
      <ul class="yanbie">
        <li @click="bdfn('OS')">左眼</li>
        <li @click="bdfn('OD')">右眼</li>
        <li @click="bdfn('OU')">双眼</li>
        <li @click="bdfn('DL')">清除已提交的眼别数据</li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MDW",
  data() {
    return {
      showyb: false,
      value: '',
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      istjsd: false,
      sfz: [
        {
          value: '染色未出',
          label: '染色未出'
        },
        {
          value: '染色出少许',
          label: '染色出少许'
        },
        {
          value: '染色出一半',
          label: '染色出一半'
        },
        {
          value: '染色出尽',
          label: '染色出尽'
        },
        {
          value: '',
          label: ''
        }
      ],
      lx: [
        {
          value: 'IV-AP',
          label: 'IV-AP'
        },
        {
          value: 'IV-DF',
          label: 'IV-DF'
        },
        {
          value: 'IV-M',
          label: 'IV-M'
        },
        {
          value: 'DV',
          label: 'DV'
        },
        {
          value: 'RGP',
          label: 'RGP'
        },
        {
          value: 'CK-MZ',
          label: 'CK-MZ'
        },
        {
          value: 'CK-BD-K',
          label: 'CK-BD-K'
        },
        {
          value: '',
          label: ''
        }
      ],
      hqm: [
        {
          value: '1.5D',
          label: '1.5D'
        },
        {
          value: '2.0D',
          label: '2.0D'
        },
        {
          value: '2.5D',
          label: '2.5D'
        },
        {
          value: '',
          label: ''
        }
      ],
      zxdw: [
        {
          value: '居中',
          label: '居中'
        },
        {
          value: '上偏',
          label: '上偏'
        },
        {
          value: '鼻偏',
          label: '鼻偏'
        },
        {
          value: '颞偏',
          label: '颞偏'
        },
        {
          value: '',
          label: ''
        },
      ],
      ydd: [
        {
          value: '不动',
          label: '不动'
        },
        {
          value: '<1mm',
          label: '<1mm'
        },
        {
          value: '1.5mm-2mm',
          label: '1.5mm-2mm'
        },
        {
          value: '>2mm',
          label: '>2mm'
        },
        {
          value: '',
          label: ''
        }
      ],
      lyjh: [
        {
          value: '推入色',
          label: '推入色'
        },
        {
          value: '入色可',
          label: '入色可'
        },
        {
          value: '入色稍慢',
          label: '入色稍慢'
        },
        {
          value: '入色快',
          label: '入色快'
        },
        {
          value: '',
          label: ''
        },
      ],

      zygxq: [
        {
          value: '无或淡荧光区，直径约5mm',
          label: '无或淡荧光区，直径约5mm'
        },
        {
          value: '<3mm，与反转区分界不清',
          label: '<3mm，与反转区分界不清'
        },
        {
          value: '出现荧光积聚',
          label: '出现荧光积聚'
        },
        {
          value: '中央荧光素较多且弥散',
          label: '中央荧光素较多且弥散'
        },
        {
          value: '',
          label: ''
        }
      ],
      fzq: [
        {
          value: "均匀荧光亮区，宽约0.5~1mm",
          label: "均匀荧光亮区，宽约0.5~1mm"
        },
        {
          value: '荧光宽带，与平行弧区边界极清',
          label: '荧光宽带，与平行弧区边界极清'
        },
        {
          value: '有较大的气泡不易排出',
          label: '有较大的气泡不易排出'
        },
        {
          value: '荧光素较多，各弧段边界不清',
          label: '荧光素较多，各弧段边界不清'
        },
        {
          value: '下方染色积液',
          label: '下方染色积液'
        },
        {
          value: '上、下方染色积液，左、右区较细',
          label: '上、下方染色积液，左、右区较细'
        },
        {
          value: '',
          label: ''
        }
      ],
      zlq: [
        {
          value: '全周与角膜平行接触，呈均匀淡荧光环，宽约1~1.5mm',
          label: '全周与角膜平行接触，呈均匀淡荧光环，宽约1~1.5mm'
        },
        // {
        //   value: '全周与角膜平行接触，呈均匀淡荧光环，约1mm',
        //   label: '全周与角膜平行接触，呈均匀淡荧光环，约1mm'
        // },
        {
          value: '与角膜接触过紧，呈荧光黑区',
          label: '与角膜接触过紧，呈荧光黑区'
        },
        {
          value: '与角膜非环形接触，荧光环不完整',
          label: '与角膜非环形接触，荧光环不完整'
        },
        {
          value: '荧光逃逸',
          label: '荧光逃逸'
        },
        {
          value: '',
          label: ''
        }
      ],
      byq: [
        {
          value: '宽约0.5mm的荧光亮区',
          label: '宽约0.5mm的荧光亮区'
        },
        {
          value: '荧光带较细',
          label: '荧光带较细'
        },
        {
          value: '荧光带消失',
          label: '荧光带消失'
        },
        {
          value: '镜片边缘翘起，宽荧光带',
          label: '镜片边缘翘起，宽荧光带'
        },
        {
          value: '可见气泡',
          label: '可见气泡'
        },
        {
          value: '',
          label: ''
        }
      ],

      jpzj: [
        {
          value: '合适',
          label: '合适'
        },
        {
          value: '偏大',
          label: '偏大'
        },
        {
          value: '偏小',
          label: '偏小'
        }
      ],
      pdqk: [
        {
          value: '好',
          label: '好'
        },
        {
          value: '陡峭拒绝',
          label: '陡峭拒绝'
        },
        {
          value: '平坦拒绝',
          label: '平坦拒绝'
        },
        {
          value: '陡峭可接受',
          label: '陡峭可接受'
        },
        {
          value: '平坦可接受',
          label: '平坦可接受'
        }
      ],
      gj: [
        {
          value: '改成散光片',
          label: '改成散光片'
        },
        {
          value: '加大直径',
          label: '加大直径'
        },
        {
          value: '减少直径',
          label: '减少直径'
        },
        {
          value: '收紧AC',
          label: '收紧AC'
        },
        {
          value: '放松AC',
          label: '放松AC'
        },
        {
          value: '抬高PC',
          label: '抬高PC'
        }
      ],
      smcs: [
        {
          value: '中心',
          label: '中心'
        },
        {
          value: '上偏',
          label: '上偏'
        },
        {
          value: '下偏',
          label: '下偏'
        },
        {
          value: '鼻偏',
          label: '鼻偏'
        },
        {
          value: '颞偏',
          label: '颞偏'
        },
        {
          value: '抬不成环',
          label: '抬不成环'
        },
        {
          value: '中央岛',
          label: '中央岛'
        }
      ],

      dvod: false,
      dvos: false,

      tjyz: [],
      sf: [
        {
          value: '是',
          label: '是'
        },
        {
          value: '否',
          label: '否'
        },
        {
          value: '',
          label: ''
        }
      ]
    }
  },
  props: {
    name: {
      default: ''
    },
    postData: {
      default: {}
    }
  },
  created() {
    this.dvsz()
    let i = 0.5
    while (i <= 3) {
      let item = i.toString()
      if (item.length == 3 && item[item.length] != 5) item = item + '0'
      if (item.length == 1 && item[item.length-1] != 5) item = item + '.00'
      this.tjyz.push({
        value: item,
        label: item
      })
      i+=0.25
    }
    this.tjyz.push({
      value: '',
      label: ''
    })

    if (this.$store.state.corneaContact.jqtjsj && this.$store.state.corneaContact.jqtjsj.S_OP_ID === this.postData.S_OP_ID) {
      this.istjsd = true
    }
    if (this.postData.S_OP_Json.isnew) {
      if (this.$store.state.corneaContact.jqyb && this.$store.state.corneaContact.jqybyb && this.$store.state.corneaContact.jqyb.S_OP_Json.S_OP_CG_brand === this.postData.S_OP_Json.S_OP_CG_brand) {
        for (let key in this.$store.state.corneaContact.jqyb.S_OP_Json) {
          if (key.indexOf(this.$store.state.corneaContact.jqybyb) > -1) {
            this.postData.S_OP_Json[key] = this.$store.state.corneaContact.jqyb.S_OP_Json[key]
          }
          if (this.$store.state.corneaContact.jqybyb === 'OU') {
            this.postData.S_OP_Json[key] = this.$store.state.corneaContact.jqyb.S_OP_Json[key]
          }
        }
      }
    }
    this.postData.S_OP_Json.isnew = false

    if (this.postData.S_OP_Json.S_OP_CG_brand != 'CRT' && this.postData.S_OP_Json.S_OP_CG_Try_TypeOS == 'DV') {
      this.dvos = true
    }
    if (this.postData.S_OP_Json.S_OP_CG_brand != 'CRT' && this.postData.S_OP_Json.S_OP_CG_Try_TypeOD == 'DV') {
      this.dvod = true
    }
  },
  methods: {
    handleRemove(file) {
      //console.log(file);
    },
    bh(e, type) {
      // console.log(e, type)
      if (type == 'od') {
        e === 'DV' && type === 'od'?this.dvod = true:this.dvod = false
      }
      if (type == 'os') {
        e === 'DV' && type === 'os'?this.dvos = true:this.dvos = false
      }
      this.dvsz(type)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },
    tb() {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      //console.log(datas)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DSOD', datas.S_OP_R_SS_DSOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DCOD', datas.S_OP_R_SS_DCOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_XOD', datas.S_OP_R_SS_XOD)

      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DSOS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DCOS', datas.S_OP_R_SS_DCOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_XOS', datas.S_OP_R_SS_XOS)
    },

    zh(key, type=0, isod='') {
      let str = this.postData.S_OP_Json[key]
      if (str !== '' && Number(str) > 0) {
        if (type) {
          this.postData.S_OP_Json[key] = "+" + str
        }else {
          this.postData.S_OP_Json[key] = str.toString()
        }
      }else {
        this.postData.S_OP_Json[key] = str !== ''?str.toString():''
      }
      if (isod) {
        this.dvsz(isod)
      }


      if (this.postData.S_OP_Json.S_OP_CG_brand !== 'CRT') {
        if (key==='S_OP_CG_Try_DingWeiHuOD' || key==='S_OP_CG_Try_DingWeiHuOS' || key==='S_OP_CG_Try_JiangFuOD' || key==='S_OP_CG_Try_JiangFuOS') {
          if (isod === 'od') {
            if(this.postData.S_OP_Json.S_OP_CG_Try_TypeOD === 'IV-AP' && this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD && this.postData.S_OP_Json.S_OP_CG_Try_JiangFuOD) {
              this.getAP('od')
            }
          }else {
            if(this.postData.S_OP_Json.S_OP_CG_Try_TypeOS === 'IV-AP' && this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS && this.postData.S_OP_Json.S_OP_CG_Try_JiangFuOS) {
              this.getAP('os')
            }
          }
        }
      }

    },

    bdfn(type) {
      this.$store.commit('corneaContact/upjqsdp', {
        key: 'jqyb',
        val: this.postData,
        yb: type
      })
      console.log(this.$store.state.corneaContact.jqtjsj)
      this.showyb = false
      this.$message({
        message: '保存本地眼别数据成功！',
        type: 'success'
      });
    },

    tjsd() {
      this.$store.commit('corneaContact/upjqsdp', {
        key: 'jqtjsj',
        val: this.postData
      })
      this.$message({
        message: '提交试戴成功！',
        type: 'success'
      });
      this.istjsd = true
    },

    dvsz(type) {
      let arr = []
      let dwh = 3950,jh = 9.70
      for (let i = 0; i < 18; i++) {
        if (i <= 2 || i > 13) {
          dwh += 50
        }else {
          dwh += 25
        }

        if (i <= 2) {
          jh -= 0.13
        }else if (i ===  4) {
          jh -= 0.07
        } else if (i ===  11) {
          jh -= 0.05
        }else {
          jh -= 0.06
        }
        arr.push({
          dwh: dwh.toFixed(0).toString(),
          jh: jh.toFixed(2).toString(),
          zj: '10.5',
          ys: ''
        })
      }
      arr[14].jh = '8.60'
      arr[15].jh = '8.49'
      arr[16].jh = '8.39'
      arr[17].jh = '8.28'

      if (type === 'od') {
        let j = -1
        if (this.postData.S_OP_Json.S_OP_CG_Try_TypeOD === 'DV' && this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD && this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOD) {
          if (this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOD == '1.00' || this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOD == '2.00') {
             for (let i = 0; i < arr.length; i++) {
               if (arr[i].dwh == this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD) {
                 j = i
                 break
               }
             }
             if (j > -1) {
               this.postData.S_OP_Json.S_OP_CG_Try_DIAOD = arr[j].zj
               this.postData.S_OP_Json.S_OP_CG_Try_JiHuOD = arr[j].jh
               if (this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOD == '1.00') {
                 this.postData.S_OP_Json.S_OP_CG_Try_ColorOD = '蓝'
               }else {
                 this.postData.S_OP_Json.S_OP_CG_Try_ColorOD = '绿'
               }
             }
          }
          if (this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD >= 4000 || this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD <= 4550) {
            this.postData.S_OP_Json.S_OP_CG_Try_JiHuOD = '10.5'
          }else {
            this.$message('无匹配数据');
          }
        }
      }else {
        let j = -1
        if (this.postData.S_OP_Json.S_OP_CG_Try_TypeOS === 'DV' && this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS && this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOS) {
          if (this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOS == '1.00' || this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOS == '2.00') {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].dwh == this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS) {
                j = i
                break
              }
            }
            if (j > -1) {
              this.postData.S_OP_Json.S_OP_CG_Try_DIAOS = arr[j].zj
              this.postData.S_OP_Json.S_OP_CG_Try_JiHuOS = arr[j].jh
              if (this.postData.S_OP_Json.S_OP_CG_Try_adjustFactorOS == '1.00') {
                this.postData.S_OP_Json.S_OP_CG_Try_ColorOS = '蓝'
              }else {
                this.postData.S_OP_Json.S_OP_CG_Try_ColorOS = '绿'
              }
            }else {
              this.$message('暂无匹配数据');
            }
          }
          if (this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS >= 4000 || this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS <= 4550) {
            this.postData.S_OP_Json.S_OP_CG_Try_JiHuOS = '10.5'
          }else {
            this.$message('无匹配数据');
          }
        }
      }
    },

    getAP(type) {
      // console.log(type==='od')
      this._http.post(this._getUrl('ProductMDWConfig_Get'), {
        "token" : {
          "sToken" : "1220A0170505DAD873F97576AFD46A2F",    "TimeSpan" : "1691378299"
        },
        "SearchCondition" : {
          "MDWPC_DingWeiHu" : type==='od'?this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOD:this.postData.S_OP_Json.S_OP_CG_Try_DingWeiHuOS,
          "MDWPC_JiangFu" : type==='od'?this.postData.S_OP_Json.S_OP_CG_Try_JiangFuOD:this.postData.S_OP_Json.S_OP_CG_Try_JiangFuOS,
          "MDWPCGroup" : "IV-AP"
        }
      })
      .then(res => {
        // console.log(res)
        if (res.GetResult) {
          if (type === 'od') {
            this.postData.S_OP_Json.S_OP_CG_Try_DIAOD = res.GetResult.MDWPC_DIA
            this.postData.S_OP_Json.S_OP_CG_Try_ColorOD = res.GetResult.MDWPC_Color
            this.postData.S_OP_Json.S_OP_CG_Try_JiHuOD = res.GetResult.MDWPC_JiHu
          }else {
            this.postData.S_OP_Json.S_OP_CG_Try_DIAOS = res.GetResult.MDWPC_DIA
            this.postData.S_OP_Json.S_OP_CG_Try_ColorOS = res.GetResult.MDWPC_Color
            this.postData.S_OP_Json.S_OP_CG_Try_JiHuOS = res.GetResult.MDWPC_JiHu
          }
        }
      })
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
h3 {padding-top: .3rem; text-align:left;}
.mar-20 {margin-top: 30px}
.padding-20{padding-top: 15px;}
.srA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  .bts {padding-top: 1em; padding-right: .3rem}
  p {text-align: left;padding-top: .2rem;}
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: 20px;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 3vw;
      text-align: left;
      margin-right: 5px;
    }
  }
  .xz {
    li {width: 13vw}
  }
  .tj {
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      margin-top: 21px;
    }
  }
  .last {
    margin-left: 50px;
    margin-top: 32px;
  }
}
.bz p {padding-bottom: 10px;font-size: 17px; text-align: left;}
.tp {
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
}
p {
  padding-top: .3rem;
  text-align: left;
}
.yanbie {
  li {
    border-bottom: 1px solid #adadad;
    padding: 15px 0;
    color: #0000ce;
    cursor: pointer;
  }
}
</style>
